import React, { Component } from "react";
import { Navbar, Container, Nav, NavDropdown, Row, Col } from "react-bootstrap";

class Footer extends Component {
  state = {};
  render() {
    return (
      <Row className="bg-light py-4">
        <Col xs={6} md={4}>
          <h5 className="bold pb-3">Deine Website GmbH</h5>
          <Nav
            defaultActiveKey="/home"
            className="text-secondary flex-column"
            style={{ marginLeft: "-15px", fontSize: "16px" }}
          >
            <Nav.Link
              className="text-secondary"
              href="https://deine-website.ch/unser-angebot/webdesign"
            >
              Webdesign
            </Nav.Link>
            <Nav.Link
              className="text-secondary"
              href="https://deine-website.ch/unser-angebot/webdesign"
            >
              Logodesign
            </Nav.Link>
            <Nav.Link
              className="text-secondary"
              href="https://deine-website.ch/unser-angebot/webdesign"
            >
              Hochzeitswebseiten
            </Nav.Link>
            <Nav.Link
              className="text-secondary"
              href="https://deine-website.ch/unser-angebot/webdesign"
            >
              Landing Pages
            </Nav.Link>
          </Nav>
        </Col>
        <Col xs={6} md={4}>
          <h5 className="bold pb-3">Zahlungsoption</h5>
          <Nav
            defaultActiveKey="/home"
            className="text-secondary flex-column"
            style={{ marginLeft: "-15px", fontSize: "16px" }}
          >
            <Nav.Link className="text-secondary">
              Rechnung 30 Tage netto
            </Nav.Link>
            <Nav.Link className="text-secondary">Vorauszahlung (Bank)</Nav.Link>
          </Nav>
        </Col>
        <Col xs={6} md={4}>
          <h5 className="bold pb-3">Ablauf Website-Bestellung</h5>
          1. Bestellen
          <br />
          2. Inhalte übermitteln
          <br />
          3. Persönlich besprechen
          <br />
          4. Webseite ist online
          <br />
          <br />
          <button className="btn btn-primary">Zurück zur Hauptseite</button>
        </Col>
      </Row>
    );
  }
}

export default Footer;
