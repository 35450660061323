import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ProductModal = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <div className="d-grid gap-2 m-1">
        <Button
          variant="btn"
          onClick={handleShow}
          className={
            props.active === "true"
              ? "active btn-outline-secondary"
              : "btn secondary"
          }
        >
          <i className={props.product.icon} style={{ fontSize: "50px" }}></i>
          <h6 className="">{props.product.title}</h6>
          <h6 className="small">CHF {props.product.price}.-</h6>
        </Button>
      </div>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.product.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6 text-center">
              <i
                className={props.product.icon}
                style={{ fontSize: "60px" }}
              ></i>
              <h5>{props.product.price} CHF</h5>
            </div>
            <div className="col-6">
              <p>{props.product.description}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="bg-light" onClick={handleClose}>
            Schliessen
          </Button>
          <Button
            variant="primary"
            onClick={function (event) {
              handleClose();
              props.onAddProduct(props.product);
            }}
          >
            Hinzufügen <i className="bi bi-bag-plus"></i>
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ProductModal;
