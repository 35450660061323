import React, { Component } from "react";
import ProductModal from "../../hooks/ProductModal";
import Modal from "react-bootstrap/Modal";
import parse from "html-react-parser";
import { Accordion, Card, Button, Container, Row, Col } from "react-bootstrap";

class GraphicdesignBasic extends Component {
  state = {
    mainProducts: this.props.products.filter(
      (e) => e.mainCategory === "Grafikdesign" && e.subCategory === "Package"
    ),
    additionalProducts: this.props.products.filter(
      (e) =>
        e.mainCategory === "Grafikdesign" && e.subCategory === "Zusatzdienst"
    ),
  };

  getMainProducts(cart) {
    let mainProducts = this.props.products.filter(
      (e) => e.mainCategory === "Grafikdesign" && e.subCategory === "Package"
    );

    let foundIndex;

    mainProducts.map((p) => {
      foundIndex = cart.findIndex((item) => item.productId === p.id);
      if (foundIndex === -1) {
        p.active = "false";
      } else {
        p.active = "true";
      }
    });

    return mainProducts;
  }

  getAdditionalProducts(cart) {
    let additionalProducts = this.props.products.filter(
      (e) =>
        e.mainCategory === "Grafikdesign" && e.subCategory === "Zusatzdienst"
    );

    let foundIndex;

    additionalProducts.map((p) => {
      foundIndex = cart.findIndex((item) => item.productId === p.id);
      if (foundIndex === -1) {
        p.active = "false";
      } else {
        p.active = "true";
      }
    });

    return additionalProducts;
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <b>Visitenkarte (einseitig)</b>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  {this.getMainProducts(this.props.entry)
                    .filter((e) => e.category === "Visitenkarte (einseitig)")
                    .map((product) => (
                      <Col xs={12} md={4} className="py-2 mb-3 d-grid gap-2">
                        <button
                          type="button"
                          onClick={() => this.props.onAddProduct(product)}
                          className={
                            product.active === "true"
                              ? "btn btn-primary border border-2 border-primary text-light nav-link active"
                              : "btn btn-outline-primary border border-2 border-primary"
                          }
                        >
                          <h4>{product.title}</h4>
                          <p>{parse(product.description)}</p>
                          <h5>CHF {product.price}.-</h5>
                        </button>
                      </Col>
                    ))}
                </Row>
                <Row className="bg-light py-3">
                  <h2 style={{ fontSize: "20px" }}>Zusatzfunktionen</h2>
                  <p>Wähle weitere Zusatzdienste für deine Visitenkarte.</p>
                  {this.getAdditionalProducts(this.props.entry)
                    .filter((e) => e.category === "Visitenkarte (einseitig)")
                    .map((product) => (
                      <Col xs={6} md={3} className="text-center">
                        <ProductModal
                          product={product}
                          onAddProduct={this.props.onAddProduct}
                          active={product.active}
                        />
                      </Col>
                    ))}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <b>Briefpapier</b>
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  {this.state.mainProducts
                    .filter((e) => e.category === "Briefpapier")
                    .map((product) => (
                      <Col xs={12} md={4} className="py-2 mb-3 d-grid gap-2">
                        <button
                          type="button"
                          onClick={() => this.props.onAddProduct(product)}
                          className={
                            product.active === "true"
                              ? "btn btn-primary border border-2 border-primary text-light nav-link active"
                              : "btn btn-outline-primary border border-2 border-primary"
                          }
                        >
                          <h4>{product.title}</h4>
                          <p>{parse(product.shortDescription)}</p>
                          <h5>CHF {product.price}.-</h5>
                        </button>
                      </Col>
                    ))}
                </div>
                <div className="row bg-light p-4">
                  <h2 style={{ fontSize: "20px" }}>Zusatzfunktionen</h2>
                  <p>Wähle weitere Zusatzdienste für dein Briefpapier.</p>
                  {this.state.additionalProducts
                    .filter((e) => e.category === "Briefpapier")
                    .map((product) => (
                      <Col xs={6} md={3} className="text-center">
                        <ProductModal
                          product={product}
                          onAddProduct={this.props.onAddProduct}
                          active={product.active}
                        />
                      </Col>
                    ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <b>Couverts</b>
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  {this.state.mainProducts
                    .filter((e) => e.category === "Couverts")
                    .map((product) => (
                      <Col xs={12} md={4} className="py-2 mb-3 d-grid gap-2">
                        <button
                          type="button"
                          onClick={() => this.props.onAddProduct(product)}
                          className={
                            product.active === "true"
                              ? "btn btn-primary border border-2 border-primary text-light nav-link active"
                              : "btn btn-outline-primary border border-2 border-primary"
                          }
                        >
                          <h4>{product.title}</h4>
                          <p>{parse(product.shortDescription)}</p>
                          <h5>CHF {product.price}.-</h5>
                        </button>
                      </Col>
                    ))}
                </div>
                <div className="row bg-light p-4">
                  <h2 style={{ fontSize: "20px" }}>Zusatzfunktionen</h2>
                  <p>Wähle weitere Zusatzdienste für deine Couvert.</p>
                  {this.state.additionalProducts
                    .filter((e) => e.category === "Couverts")
                    .map((product) => (
                      <Col xs={6} md={3} className="text-center">
                        <ProductModal
                          product={product}
                          onAddProduct={this.props.onAddProduct}
                          active={product.active}
                        />
                      </Col>
                    ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <b>Logodesign</b>
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  {this.state.mainProducts
                    .filter((e) => e.category === "Logodesign")
                    .map((product) => (
                      <Col xs={12} md={4} className="py-2 mb-3 d-grid gap-2">
                        <button
                          type="button"
                          onClick={() => this.props.onAddProduct(product)}
                          className={
                            product.active === "true"
                              ? "btn btn-primary border border-2 border-primary text-light nav-link active"
                              : "btn btn-outline-primary border border-2 border-primary"
                          }
                        >
                          <h4>{product.title}</h4>
                          <p>{parse(product.shortDescription)}</p>
                          <h5>CHF {product.price}.-</h5>
                        </button>
                      </Col>
                    ))}
                </div>
                <div className="row bg-light p-4">
                  <h2 style={{ fontSize: "20px" }}>Zusatzfunktionen</h2>
                  <p>Wähle weitere Zusatzdienste für deine Couvert.</p>
                  {this.state.additionalProducts
                    .filter((e) => e.category === "Logodesign")
                    .map((product) => (
                      <Col xs={6} md={3} className="text-center">
                        <ProductModal
                          product={product}
                          onAddProduct={this.props.onAddProduct}
                          active={product.active}
                        />
                      </Col>
                    ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
        <Row className="py-5">
          <Col md="9">
            <div className="lc-block">
              <div editable="rich">
                <h2>
                  <strong>Zurück zum Webdesign?</strong>
                </h2>

                <p>
                  Wechsle zurück zu unseren Webdesign-Services oder schliesse
                  deine Bestellung gleich durch ausfüllen des untenstehenden
                  Formulars ab.
                </p>
              </div>
            </div>
          </Col>
          <Col md="3" className="align-self-center text-center">
            <div className="lc-block">
              <button
                className="btn btn-link btn-lg"
                onClick={() => this.props.onChangeView()}
              >
                Zum Webdesign wechseln
              </button>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default GraphicdesignBasic;
