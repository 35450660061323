import React, { Component } from "react";

class Entry extends Component {
  state = {};

  defineLiClass() {
    let liClassName = "";
    if (this.props.entry.productData[0].entryType === 4) {
      liClassName = "bg-light list-group-item d-flex justify-content-between";
    } else if (this.props.entry.productData[0].entryType === 2) {
      liClassName = "bg-success list-group-item d-flex justify-content-between";
    } else {
      liClassName = "list-group-item d-flex justify-content-between 1h-sm";
    }
    return liClassName;
  }

  defineSpanClass() {
    let spanClassName = "text-muted";
    if (this.props.entry.productData[0].entryType === 2) {
      spanClassName = "text-light";
    }
    return spanClassName;
  }

  render() {
    return (
      <li className="bg-light list-group-item d-flex justify-content-between">
        <div className="float-start col-8">
          <h6 className={"my-0"}>
            {this.props.entry.productData[0].title}
            {/* <button
              style={{ fontSize: "10px", marginLeft: "10px" }}
              onClick={() =>
                this.props.onRemoveProduct(this.props.entry.entryId)
              }
              className="btn text-danger"
            >
              entfernen
            </button> */}
          </h6>
          <p
            className="bg-light"
            style={{
              fontSize: "12px",
              marginBottom: "0px",
            }}
          >
            {this.props.entry.productData[0].shortDescription}
          </p>
        </div>
        <p
          className="text-right"
          style={{
            marginBottom: "-10px",
          }}
        >
          CHF {this.props.entry.productData[0].price}.- <br></br>
          <button
            style={{
              fontSize: "10px",
              marginLeft: "10px",
              marginBottom: "-0px",
            }}
            onClick={() => this.props.onRemoveProduct(this.props.entry.entryId)}
            className="btn text-danger"
          >
            entfernen
          </button>
        </p>
      </li>
    );
  }
}

export default Entry;
