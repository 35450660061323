import React, { Component } from "react";
import { Navbar, Container, Nav, NavDropdown, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class Header extends Component {
  state = {};
  render() {
    return (
      <Col>
        <Navbar
          expand="lg"
          variant="light"
          bg="transparent"
          style={{
            minHeight: "90px",
            color: "#d2d2d2",
          }}
        >
          <Container fluid className="fixed-top shadow-sm p-3 bg-white rounded">
            <Navbar.Brand href="https://deine-website.ch">
              {" "}
              <img
                src="https://deine-website.ch/wp-content/uploads/2022/02/deine-website-logo-blue-small.svg"
                width="180px"
              />
            </Navbar.Brand>
            {/* <Nav className="me-auto">
              <Nav.Link
                className="px-4 px-4 link-dark menuLink"
                href="https://deine-website.ch/unser-angebot/webdesign"
              >
                Webdesign
              </Nav.Link>
              <Nav.Link
                className="px-4 link-dark menuLink"
                href="https://deine-website.ch/unser-angebot/grafikdesign/"
              >
                Grafikdesign
              </Nav.Link>
              <Nav.Link
                className="px-4 link-dark menuLink"
                href="https://deine-website.ch/uber-uns/"
              >
                Über uns
              </Nav.Link>
              <Nav.Link
                className="px-4 link-dark menuLink"
                href="https://deine-website.ch/kontakt"
              >
                Kontakt
              </Nav.Link>
              <Nav.Link
                className="px-4 link-dark menuLink"
                href="https://deine-website.ch/support"
              >
                Support
              </Nav.Link>
            </Nav> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            {/* <Link to={"/admin"}>Admin</Link>
            {" - "}
            <Link to={"/contentform"}>ContentForm</Link>
            {" - "}
            <Link to={"/"}>OrderForm</Link> */}
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link
                  className="px-4 px-4 link-dark menuLink"
                  href="https://deine-website.ch/unser-angebot/webdesign"
                >
                  Webdesign
                </Nav.Link>
                <Nav.Link
                  className="px-4 link-dark menuLink"
                  href="https://deine-website.ch/unser-angebot/logodesign"
                >
                  Grafikdesign
                </Nav.Link>
                <Nav.Link
                  className="px-4 link-dark menuLink"
                  href="https://deine-website.ch/uber-uns/"
                >
                  Über uns
                </Nav.Link>
                <Nav.Link
                  className="px-4 link-dark menuLink"
                  href="https://deine-website.ch/kontakt"
                >
                  Kontakt
                </Nav.Link>
                <Nav.Link
                  className="px-4 link-dark menuLink"
                  href="https://deine-website.ch/support"
                >
                  Support
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Col>
    );
  }
}

export default Header;
