import React, { Component } from "react";
import ProductModal from "../../hooks/ProductModal";
import {
  Accordion,
  Card,
  Button,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import parse from "html-react-parser";

class WebdesignBasic extends Component {
  state = {};

  getMainProducts(cart) {
    let mainProducts = this.props.products.filter(
      (e) => e.mainCategory === "Webdesign" && e.subCategory === "Package"
    );

    let foundIndex;

    mainProducts.map((p) => {
      foundIndex = cart.findIndex((item) => item.productId === p.id);
      if (foundIndex === -1) {
        p.active = "false";
      } else {
        p.active = "true";
      }
    });

    return mainProducts;
  }

  getAdditionalProducts(cart) {
    let additionalProducts = this.props.products.filter(
      (e) => e.mainCategory === "Webdesign" && e.subCategory === "Zusatzdienst"
    );

    let foundIndex;

    additionalProducts.map((p) => {
      foundIndex = cart.findIndex((item) => item.productId === p.id);
      if (foundIndex === -1) {
        p.active = "false";
      } else {
        p.active = "true";
      }
    });

    return additionalProducts;
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <b>Webdesign</b>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  {this.getMainProducts(this.props.entry).map((product) => (
                    <Col xs={12} md={4} className="py-2 mb-3 d-grid gap-2">
                      <button
                        type="button"
                        onClick={() => this.props.onAddProduct(product)}
                        className={
                          product.active === "true"
                            ? "btn btn-primary border border-2 border-primary text-light nav-link active"
                            : "btn btn-outline-primary border border-2 border-primary"
                        }
                      >
                        <h4>{product.title}</h4>
                        <p>{parse(product.description)}</p>
                        <h5>CHF {product.price}.-</h5>
                        <span style={{ fontSize: "12px" }}>
                          Hosting & Update{" "}
                          <b>CHF {product.pricePerMonth}.-/Mt.</b>
                        </span>
                      </button>
                    </Col>
                  ))}
                </Row>
                <Row className="bg-light py-3">
                  <h2 style={{ fontSize: "20px" }}>Zusatzfunktionen</h2>
                  <p>
                    Wähle weitere Zusatzdienste für deine professionelle
                    Website.
                  </p>
                  {this.getAdditionalProducts(this.props.entry).map(
                    (product) => (
                      <Col xs={6} md={3} className="text-center">
                        <ProductModal
                          product={product}
                          onAddProduct={this.props.onAddProduct}
                          active={product.active}
                        />
                      </Col>
                    )
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
        <Row className="py-5">
          <Col md="9">
            <div className="lc-block">
              <div editable="rich">
                <h2>
                  <strong>Briefpapier, Visitenkarten oder Logo?</strong>
                </h2>

                <p>
                  Wir übernehmen für dich die Erstellung von individuell
                  gestaltetem Briefpapier, Couverts, Visitenkarten oder Logo. Du
                  erhälst bei uns alles aus einer Hand und wir stimmen das
                  Deisgn auf deine Website ab für einen starken, einheitlichen
                  Auftritt.
                </p>
              </div>
            </div>
          </Col>
          <Col md="3" className="align-self-center text-center">
            <div className="lc-block">
              <button
                className="btn btn-link btn-lg"
                onClick={() => this.props.onChangeView()}
              >
                Zum Grafikdesign wechseln
              </button>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default WebdesignBasic;
