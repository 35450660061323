import React, { Component } from "react";

class Faq extends Component {
  state = {};

  render() {
    return (
      <div className="sticky-top card rounded-1 border-0 bg-transparent">
        {/* <h5 style={{ paddingLeft: "10px", paddingTop: "10px" }}>
          <span className="text-secundary">Häufige Fragen / Kontakt</span>
        </h5>
        <ul>
          <li>Test</li>
        </ul> */}
      </div>
    );
  }
}

export default Faq;
