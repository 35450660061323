import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

const OrderForm = (props) => {
  /*   const sgMail = require("@sendgrid/mail"); */

  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [showSending, setShowSending] = useState(false);
  const [showForm, setShowForm] = useState(true);

  const handleShowSuccess = () => setShowSuccess(true);
  const handleShowFailure = () => setShowFailure(true);
  const handleShowSending = () => setShowSending(true);
  const handleHideSending = () => setShowSending(false);
  const handleShowForm = () => setShowForm(true);
  const handleHideForm = () => setShowForm(false);

  const form = React.createRef();
  const entrys = props.entry;

  const sendMail = (e, props) => {
    const message = renderentries(entrys);
    handleShowSending();
    handleHideForm();

    emailjs
      .send(
        "service_oyjip5q",
        "template_d6vf755",
        {
          message: message,
          vorname: e.vorname,
          nachname: e.nachname,
          strasse: e.strasse,
          plz: e.plz,
          ort: e.ort,
          email: e.email,
          telefon: e.telefon,
          terms: e.terms,
        },
        "user_PD61xh5VeI04zbxiSoGWT"
      )
      .then(
        (result) => {
          handleHideSending();
          handleShowSuccess();
        },
        (error) => {
          handleHideSending();
          handleShowFailure();
          handleShowForm();
        }
      );
  };

  const renderentries = (entry) => {
    let message = "<p>";
    entry
      .filter((e) => e.productId <= 100)
      .map(
        (entry) =>
          (message =
            message +
            "<b>" +
            entry.productData[0].title +
            "</b><br>" +
            entry.productData[0].shortDescription +
            " (CHF " +
            entry.productData[0].price +
            ".-) / (CHF " +
            entry.productData[0].pricePerMonth +
            ".- pro Mt.)" +
            "<br><br>")
      );
    message =
      message +
      "<br><h3>Total: </h3><br>CHF " +
      props.costCreation +
      ".- Erstellung (einmalig)" +
      "<br>CHF " +
      props.costPerMonth +
      ".- Update & Hosting (pro Monat)" +
      "</p>";
    return message;
  };

  const schema = yup.object().shape({
    vorname: yup.string().required(),
    nachname: yup.string().required(),
    strasse: yup.string().required(),
    plz: yup.string().required(),
    ort: yup.string().required(),
    email: yup.string().email().required(),
    telefon: yup.string().required(),
    terms: yup
      .bool()
      .required()
      .oneOf([true], "AGB's müssen akzeptiert werden."),
  });

  return (
    <>
      <Row>
        <Col md="12" className="pb-4">
          <h2 className="py-2">Jetzt Bestellung abschliessen</h2>
          {showSuccess === true ? (
            <Alert key="success" variant="success">
              Die Bestellung wurde erfolgreich versendet.
            </Alert>
          ) : (
            ""
          )}
          {showFailure === true ? (
            <Alert key="success" variant="danger">
              Oh je, da ist ein Fehler aufgetreten, bitte versuche es erneut
              oder schreibe uns direkt per E-mail an{" "}
              <a href="mailto:hello@deine-website.ch">hello@deine-website.ch</a>
              .
            </Alert>
          ) : (
            ""
          )}
          {showSending === true ? (
            <Alert key="success" variant="info">
              <div class="spinner-border" role="status"></div> Deine Bestellung
              wird versendet, warte bitte noch einen Augenblick.
            </Alert>
          ) : (
            ""
          )}
          {showForm === true ? (
            <Formik
              validationSchema={schema}
              onSubmit={sendMail}
              initialValues={{
                vorname: "",
                nachname: "",
                strasse: "",
                plz: "",
                ort: "",
                email: "",
                telefon: "",
                terms: false,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form ref={form} noValidate onSubmit={handleSubmit}>
                  <Row>
                    <Form.Group
                      as={Col}
                      xs="12"
                      md="3"
                      className="mb-3"
                      controlId="formBasicVorname"
                    >
                      <Form.Label>Vorname</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="vorname"
                        value={values.vorname}
                        onChange={handleChange}
                        isValid={touched.vorname && !errors.vorname}
                        isInvalid={!!errors.vorname}
                        feedback={errors.vorname}
                        feedbackType="invalid"
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte gib deinen Vornamen ein.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      xs="12"
                      md="3"
                      className="mb-3"
                      controlId="formBasicName"
                    >
                      <Form.Label>Nachname</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="nachname"
                        value={values.nachname}
                        onChange={handleChange}
                        isValid={touched.nachname && !errors.nachname}
                        isInvalid={!!errors.nachname}
                        feedback={errors.nachname}
                        feedbackType="invalid"
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte gib deinen Nachnamen ein.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      xs="12"
                      md="6"
                      className="mb-3"
                      controlId="formBasicEMail"
                    >
                      <Form.Label>E-Mail</Form.Label>
                      <Form.Control
                        required
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                        feedback={errors.email}
                        feedbackType="invalid"
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte gib eine gültige E-Mail ein.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      xs="12"
                      md="6"
                      className="mb-3"
                      controlId="formBasicStrasse"
                    >
                      <Form.Label>Strasse & Nr.</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="strasse"
                        value={values.strasse}
                        onChange={handleChange}
                        isValid={touched.strasse && !errors.strasse}
                        isInvalid={!!errors.strasse}
                        feedback={errors.strasse}
                        feedbackType="invalid"
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte gib eine Strasse & Nr. ein.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      xs="12"
                      md="6"
                      className="mb-3"
                      controlId="formBasicTelefon"
                    >
                      <Form.Label>Telefon</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="telefon"
                        value={values.telefon}
                        onChange={handleChange}
                        isValid={touched.telefon && !errors.telefon}
                        isInvalid={!!errors.telefon}
                        feedback={errors.telefon}
                        feedbackType="invalid"
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte gib eine gültige Telefonnummer ein.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      xs="4"
                      md="2"
                      className="mb-3"
                      controlId="formBasicPLZ"
                    >
                      <Form.Label>PLZ</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="plz"
                        value={values.plz}
                        onChange={handleChange}
                        isValid={touched.plz && !errors.plz}
                        isInvalid={!!errors.plz}
                        feedback={errors.plz}
                        feedbackType="invalid"
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte gib eine gültige PLZ ein.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      xs="8"
                      md="4"
                      className="mb-3"
                      controlId="formBasicOrt"
                    >
                      <Form.Label>Ort</Form.Label>
                      <Form.Control
                        type="text"
                        name="ort"
                        value={values.ort}
                        onChange={handleChange}
                        isValid={touched.ort && !errors.ort}
                        isInvalid={!!errors.ort}
                        feedback={errors.ort}
                        feedbackType="invalid"
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte gib einen gültigen Ort ein.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="py-3">
                      <Form.Check
                        required
                        className="small"
                        name="terms"
                        label="Ich habe die AGB's gelesen und akzeptiere diese."
                        onChange={handleChange}
                        isInvalid={!!errors.terms}
                        feedback={errors.terms}
                        feedbackType="invalid"
                        id="validationFormik0"
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      xs="12"
                      md="12"
                      controlId="formBasicOrt"
                    >
                      <div className="d-grid gap-2">
                        <Button className="my-2 py-2" type="submit">
                          Bestellung abschicken
                        </Button>
                      </div>
                    </Form.Group>
                  </Row>
                </Form>
              )}
            </Formik>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </>
  );
};

export default OrderForm;
