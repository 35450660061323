import React, { Component } from "react";
import Entries from "./entries";
import Faq from "./faq";

class Summary extends Component {
  state = {};

  render() {
    return (
      <div className="sticky-top card rounded-1 border-0 pt-5 bg-transparent">
        <div className="card-body">
          <h3
            style={{
              paddingTop: "30px",
              paddingLeft: "10px",
            }}
          >
            <span className="text-secundary">Zusammenfassung</span>
          </h3>
          {this.props.entry.length === 0 ? (
            <div className="text-center p-3">
              <h5>Aktuell wurde noch kein Service ausgewählt.</h5>
              <p>
                Beginne jetzt mit dem Zusammenstellen deines gewünschten Paketes
              </p>
              <span style={{ fontSize: "40px", float: "center" }}>
                <i className="bi bi-bag"></i>
              </span>
            </div>
          ) : (
            <Entries
              key={this.props.entry.entryId}
              id={this.props.entry.entryId}
              entry={this.props.entry.sort(function (a, b) {
                return a.entryId - b.entryId;
              })}
              promoCode={this.props.promoCode}
              costCreation={this.props.costCreation}
              costPerMonth={this.props.costPerMonth}
              checkPromoCode={this.props.checkPromoCode}
              onAddProduct={this.props.onAddProduct}
              onRemoveProduct={this.props.onRemoveProduct}
            />
          )}
        </div>
        <Faq />
      </div>
    );
  }
}

export default Summary;
