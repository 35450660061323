import React, { Component } from "react";
import "./css/custom.css";
import "./css/product.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Admin from "./components/admin/container";
import ContentForm from "./components/contentform/container";
import OrderForm from "./components/orderform/container";
import { Route, Routes } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <OrderForm />
      /*  <Routes>
        <Route path="/admin" element={<Admin />} />
        <Route path="/contentform" element={<ContentForm />} />
        <Route path="/" exact element={<OrderForm />} />
      </Routes> */
    );
    /*  return <ContentForm />; */
  }
}

export default App;
