import React, { Component } from "react";
import Form from "./form";
import Header from "../header";
import Footer from "./footer";
import Summary from "./summary";
import product from "../../data/product_data.json";
import * as _ from "lodash";
import OrderForm from "../../hooks/orderForm";
import { Accordion, Card, Button, Container, Row, Col } from "react-bootstrap";

class MainContainer extends Component {
  state = {
    product,
    entry: [],
    title: "Bestellformular",
    entryIdCount: 0,
    costCreation: 0,
    costPerMonth: 0,
    category: [
      {
        id: 1,
        description: "Webdesign",
      },
      {
        id: 2,
        description: "Logodesign",
      },
      {
        id: 3,
        description: "Firmendokumente",
      },
    ],
    promoCode: {
      code: "",
      description: "",
      value: 0,
      type: "",
      state: "inactive",
    },
  };

  /*   constructor() {
    super();
    this.handleRemoveProduct = this.handleRemoveProduct(this);
    this.handleAddProduct = this.handleAddProduct(this); 
  } */

  handleAddProduct = (product, entryToRemove) => {
    const getAssociations = () => {
      fetch("/api/test").then((result) => result);
    };

    // create new array with current state of entries
    let EntryState = this.state.entry;
    const entryIdCount = this.state.entryIdCount + 1;

    const isCartEmpty = this.state.entry.length === 0 ? true : false;

    const isProductWithSameProductGroupInCart =
      this.isProductWithSameProductGroupInCart(product.productGroup, EntryState)
        .length === 0
        ? false
        : true;

    const isPackageOfProductInCart = this.isPackageOfProductInCart(
      product,
      EntryState
    );

    const productWithSameProductGroup =
      this.isProductWithSameProductGroupInCart(
        product.productGroup,
        EntryState
      )[0];

    if (isCartEmpty && isPackageOfProductInCart) {
      let entryIdCount = this.state.entryIdCount + 1;
      let productToAdd = {
        entryId: entryIdCount,
        productId: this.state.product.data.filter((e) => e.id === product.id)[0]
          .id,
        mainCategory: this.state.product.data.filter(
          (e) => e.id === product.id
        )[0].mainCategory,
        productCategory: this.state.product.data.filter(
          (e) => e.id === product.id
        )[0].category,
        productSubCategory: this.state.product.data.filter(
          (e) => e.id === product.id
        )[0].subCategory,
        productData: this.state.product.data.filter((e) => e.id === product.id),
      };
      EntryState.push(productToAdd);
    } else if (isCartEmpty && !isPackageOfProductInCart) {
      // Fehlermeldung hier ausgeben
      alert("Bitte zuerst Hauptprodukt hinzufügen");
    }
    if (!isCartEmpty) {
      if (isProductWithSameProductGroupInCart) {
        let entryIdCount = this.state.entryIdCount + 1;
        let productToAdd = {
          entryId: entryIdCount,
          productId: this.state.product.data.filter(
            (e) => e.id === product.id
          )[0].id,
          mainCategory: this.state.product.data.filter(
            (e) => e.id === product.id
          )[0].mainCategory,
          productCategory: this.state.product.data.filter(
            (e) => e.id === product.id
          )[0].category,
          productSubCategory: this.state.product.data.filter(
            (e) => e.id === product.id
          )[0].subCategory,
          productData: this.state.product.data.filter(
            (e) => e.id === product.id
          ),
        };
        EntryState.push(productToAdd);

        if (productToAdd.productData[0].entryType !== 3) {
          // Remove
          EntryState = EntryState.filter(
            (e) => e.entryId !== productWithSameProductGroup.entryId
          );
        }
      } else if (
        !isProductWithSameProductGroupInCart &&
        isPackageOfProductInCart
      ) {
        let entryIdCount = this.state.entryIdCount + 1;
        let productToAdd = {
          entryId: entryIdCount,
          productId: this.state.product.data.filter(
            (e) => e.id === product.id
          )[0].id,
          mainCategory: this.state.product.data.filter(
            (e) => e.id === product.id
          )[0].mainCategory,
          productCategory: this.state.product.data.filter(
            (e) => e.id === product.id
          )[0].category,
          productSubCategory: this.state.product.data.filter(
            (e) => e.id === product.id
          )[0].subCategory,
          productData: this.state.product.data.filter(
            (e) => e.id === product.id
          ),
        };
        EntryState.push(productToAdd);
      } else if (
        !isProductWithSameProductGroupInCart &&
        !isPackageOfProductInCart
      ) {
        // Fehlermeldung hier ausgeben
        alert("Bitte zuerst Hauptprodukt wählen.");
      }
    }
    const newCost = this.calculateCost(EntryState);
    this.setState({
      entry: EntryState,
    });
    this.setState({ entryIdCount: entryIdCount });
    this.setState({ costCreation: newCost.cost.costCreation });
    this.setState({ costPerMonth: newCost.cost.costPerMonth });
  };

  addProductToCart = (product) => {
    let newEntryState = this.state.entry;
    let entryIdCount = this.state.entryIdCount + 1;
    let productToAdd = {
      entryId: entryIdCount,
      productData: this.state.product.data.filter((e) => e.id === product.id),
    };
    newEntryState.push(productToAdd);
  };

  addAndRemoveProductToCart = (productToAdd, entryIdToRemove) => {
    /* let newEntryState = this.state.entry; */
    let newEntryState = [];
    for (var i = 0; i < this.state.entry.length; i++) {
      newEntryState.push(this.state.entry[i]);
    }
    let entryIdCount = this.state.entryIdCount + 1;
    let newProductToAdd = {
      entryId: entryIdCount,
      productData: this.state.product.data.filter(
        (e) => e.id === productToAdd.id
      ),
    };
    newEntryState.push(newProductToAdd);
    const new2EntryState = newEntryState.filter(
      (e) => e.entryId !== entryIdToRemove
    );
    this.setState({ entry: new2EntryState });
    this.setState({ entryIdCount: entryIdCount });
    const newCost = this.calculateCost(new2EntryState);

    this.setState({ costCreation: newCost.cost.costCreation });
    this.setState({ costPerMonth: newCost.cost.costPerMonth });

    return new2EntryState;
  };

  handleRemoveProduct = (entryId) => {
    const entries = this.state.entry.filter((e) => e.entryId !== entryId);
    this.setState({ entry: entries });
    const newCost = this.calculateCost(entries);
    this.setState({ costCreation: newCost.cost.costCreation });
    this.setState({ costPerMonth: newCost.cost.costPerMonth });
  };

  isProductInCart = (productId) => {
    if (this.state.entry.length !== 0) {
      let result = false;
      let productsInCart = [];
      for (var i = 0; i < this.state.entry.length; i++) {
        productsInCart.push(this.state.entry[i].productData[0]);
        if (this.state.entry[i].productData[0].id === productId) {
          result = true;
          return result;
        }
      }
      return result;
    }
  };

  isProductWithSameProductGroupInCart = (productGroup, cart) => {
    let foundEntry = [];
    if (cart.length !== 0) {
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].productData[0].productGroup === productGroup) {
          foundEntry.push(cart[i]);
        }
      }
    }
    return foundEntry;
  };

  isPackageOfProductInCart = (product, cart) => {
    let foundEntry = false;
    if (product.subCategory === "Package") {
      return true;
    } else {
      for (var i = 0; i < cart.length; i++) {
        if (
          cart[i].productData[0].subCategory === "Package" &&
          cart[i].productData[0].category === product.category
        ) {
          /* foundEntry.push(cart[i]); */
          foundEntry = true;
        }
      }
    }
    return foundEntry;
  };

  calculateCost(entry) {
    let costCreation = 0;
    let costPerMonth = 0;
    let promoCode = 0;

    for (var i = 0; i < entry.length; i++) {
      costCreation += entry[i].productData[0].price;
      costPerMonth += entry[i].productData[0].pricePerMonth;
    }

    if (this.state.promoCode.state === "active") {
      if (this.state.promoCode.type === "absolute") {
        promoCode = this.state.promoCode.value;
      } else if (this.state.promoCode.type === "relative") {
        promoCode = costCreation * (this.state.promoCode.value / 100);
      }
    }

    return {
      cost: {
        costCreation: costCreation - promoCode,
        costPerMonth: costPerMonth,
      },
    };
  }

  checkPromoCode = (promoCode) => {
    const code = this.state.product.data.filter((p) => promoCode === p.code);

    if (code.length === 0) {
      alert("Kein Code gefunden!");
    } else {
      this.setState({
        promoCode: {
          code: code[0].code,
          description: code[0].description,
          value: code[0].value,
          type: code[0].type,
          state: "active",
        },
      });

      const newCost = this.calculateCost(this.state.entry);

      let promoValue = 0;

      if (code[0].state === "active") {
        if (code[0].type === "absolute") {
          promoValue = code[0].value;
        } else if (code[0].type === "relative") {
          promoValue = newCost.cost.costCreation * (code[0].value / 100);
        }
      }

      this.setState({
        costCreation: newCost.cost.costCreation - promoValue,
      });
      this.setState({ costPerMonth: newCost.cost.costPerMonth });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <Header />
              <h1 className="pt-4">Bestellformular</h1>
              <Form
                products={this.state.product.data}
                category={this.state.category}
                onAddProduct={this.handleAddProduct}
                entry={this.state.entry}
                costCreation={this.state.costCreation}
                costPerMonth={this.state.costPerMonth}
                onRemoveProduct={this.handleRemoveProduct}
                checkPromoCode={this.checkPromoCode}
                promoCode={this.state.promoCode}
                contactUs={this.contactUs}
              />
            </div>
            <div className="bg-image summary fixed col-12 col-md-4 d-none d-sm-block">
              <Summary
                entry={_.sortBy(this.state.entry, "productId")}
                costCreation={this.state.costCreation}
                costPerMonth={this.state.costPerMonth}
                onAddProduct={this.handleAddProduct}
                onRemoveProduct={this.handleRemoveProduct}
                checkPromoCode={this.checkPromoCode}
                promoCode={this.state.promoCode}
                contactUs={this.contactUs}
              />
            </div>
          </div>
        </div>
        <Container className="d-block d-sm-none fixed-bottom bg-light py-3 shadow-lg bg-body rounded">
          <a href="#summary" style={{ textDecoration: "none" }}>
            <Row>
              <Col xs="7" className="text-right">
                <h2>
                  Warenkorb{" "}
                  <span
                    className="badge rounded-pill bg-primary"
                    /* style={{ fontSize: "16px" }} */
                  >
                    <i className="bi bi-cart2"></i> {this.state.entry.length}
                  </span>
                </h2>
              </Col>
              <Col xs="5" className="text-right d-flex">
                <h2>
                  {" "}
                  <small>CHF {this.state.costCreation} .-</small>
                </h2>
              </Col>
            </Row>
            <Row>
              <Col xs="7" className="text-right">
                <span style={{ fontSize: "14px" }}>Hosting & Update</span>
              </Col>
              <Col xs="5" className="text-right d-flex">
                <span style={{ fontSize: "14px" }}>
                  CHF {this.state.costPerMonth} .- / Mt.
                </span>
              </Col>
            </Row>
          </a>
        </Container>
        <Container fluid className="bg-light">
          <Container>
            <Row>
              <Footer />
            </Row>
          </Container>
        </Container>
      </React.Fragment>
    );
  }
}

export default MainContainer;
