import React, { Component } from "react";
import WebdesignBasic from "./webdesignBasic";
import Summary from "./summary";
import * as _ from "lodash";
import GraphicdesignBasic from "./graphicdesignBasic";
/* import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'; */
import OrderForm from "../../hooks/orderForm";
import {
  Accordion,
  Card,
  Button,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "Webdesign",
      title: "Bestellformular",
      category: this.props.category,
      products: this.props.products,
      promocode: {
        description: "STARTUP21",
        value: 50.0,
      },
      finalPrice: 0,
    };
    this.switchView = this.switchView.bind(this);
  }

  switchView() {
    if (this.state.active === "Webdesign") {
      this.setState({ active: "Grafikdesign" });
    } else {
      this.setState({ active: "Webdesign" });
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  }

  render() {
    return (
      <Container fluid className="p-2">
        {/*  <Switch>
              <Route exact path='/webdesign' component={<WebdesignBasic
              products={this.props.products}
              entry={this.props.entry}
              onAddProduct={this.props.onAddProduct}
              onChangeView={this.switchView}
            />} />
              <Route path='/grafikdesign' component={<GraphicdesignBasic
              products={this.props.products}
              entry={this.props.entry}
              onAddProduct={this.props.onAddProduct}
              onChangeView={this.switchView}
            />} />
          </Switch> */}
        <Row>
          <Col xs={6} md={4}>
            <button
              className={
                this.state.active === "Webdesign"
                  ? "btn btn-primary border border-2 border-primary text-light nav-link active"
                  : "btn btn-primarys border border-2 border-primary  nav-link"
              }
              aria-current="page"
              onClick={() => this.switchView("Webdesign")}
            >
              <h2>Webdesign</h2>
            </button>
          </Col>
          <Col xs={6} md={4}>
            <button
              className={
                this.state.active === "Grafikdesign"
                  ? "btn btn-primary border border-2 border-primary text-light nav-link active"
                  : "btn btn-primarys border border-2 border-primary  nav-link"
              }
              onClick={() => this.switchView("Grafikdesign")}
            >
              <h2>Grafikdesign</h2>
            </button>
          </Col>

          <p>
            <br></br>Wähle den gewünschten Service.
          </p>

          {this.state.active === "Webdesign" ? (
            <WebdesignBasic
              products={this.props.products}
              entry={this.props.entry}
              onAddProduct={this.props.onAddProduct}
              onChangeView={this.switchView}
            />
          ) : (
            <GraphicdesignBasic
              products={this.props.products}
              entry={this.props.entry}
              onAddProduct={this.props.onAddProduct}
              onChangeView={this.switchView}
            />
          )}
        </Row>
        <Row>
          <Col md={4} className="bg-image summary fixed mb-4 d-block d-sm-none">
            <a id="summary"></a>
            <Summary
              entry={this.props.entry}
              costCreation={this.props.costCreation}
              costPerMonth={this.props.costPerMonth}
              onAddProduct={this.props.onAddProduct}
              onRemoveProduct={this.props.onRemoveProduct}
              checkPromoCode={this.props.checkPromoCode}
              promoCode={this.props.promoCode}
              contactUs={this.props.contactUs}
            />
          </Col>
        </Row>
        <Row>
          <OrderForm
            entry={this.props.entry}
            costCreation={this.props.costCreation}
            costPerMonth={this.props.costPerMonth}
          />
        </Row>
      </Container>
    );
  }
}

export default Form;
