import React, { Component } from "react";
import Entry from "./entry";

class Entries extends Component {
  state = {
    promoCode: "",
  };

  handleChange = (e) => {
    let promoCode = { ...this.state.promoCode };
    promoCode = e.currentTarget.value;
    this.setState({ promoCode });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.checkPromoCode(this.state.promoCode);
  };

  render() {
    return (
      <React.Fragment>
        <ul className="list-group mb-3">
          {this.props.entry
            .filter(
              (e) =>
                e.mainCategory === "Webdesign" &&
                e.productSubCategory === "Package"
            )
            .map((entry) => (
              <Entry
                key={entry.entryId}
                id={entry.entryId}
                entry={entry}
                onAddProduct={this.props.onAddProduct}
                onRemoveProduct={this.props.onRemoveProduct}
              />
            ))}
          {this.props.entry
            .filter(
              (e) =>
                e.mainCategory === "Webdesign" &&
                e.productSubCategory === "Zusatzdienst"
            )
            .map((entry) => (
              <Entry
                key={entry.entryId}
                id={entry.entryId}
                entry={entry}
                onAddProduct={this.props.onAddProduct}
                onRemoveProduct={this.props.onRemoveProduct}
              />
            ))}
          {this.props.entry
            .filter(
              (e) =>
                e.mainCategory === "Grafikdesign" &&
                e.productSubCategory === "Package"
            )
            .map((entry) => (
              <Entry
                key={entry.entryId}
                id={entry.entryId}
                entry={entry}
                onAddProduct={this.props.onAddProduct}
                onRemoveProduct={this.props.onRemoveProduct}
              />
            ))}
          {this.props.entry
            .filter(
              (e) =>
                e.mainCategory === "Grafikdesign" &&
                e.productSubCategory === "Zusatzdienst"
            )
            .map((entry) => (
              <Entry
                key={entry.entryId}
                id={entry.entryId}
                entry={entry}
                onAddProduct={this.props.onAddProduct}
                onRemoveProduct={this.props.onRemoveProduct}
              />
            ))}
          <hr></hr>
          {this.props.promoCode.state === "active" ? (
            <li className="bg-success text-light list-group-item d-flex justify-content-between">
              <span style={{ fontSize: "14px" }}>
                Gutschein: {this.props.promoCode.code} <br />
                {this.props.promoCode.description}
              </span>
              {this.props.promoCode.type === "absolute"
                ? "CHF " + this.props.promoCode.value + ".-"
                : this.props.promoCode.value + " %"}
            </li>
          ) : (
            ""
          )}

          <li className="bg-primary text-light list-group-item d-flex justify-content-between">
            <span>Totel Erstellungskosten</span>
            <strong>
              CHF {this.props.costCreation}
              {this.props.costCreation % 1 === 0 ? ".-" : ""}
            </strong>
          </li>
          <li className="bg-primary text-light list-group-item d-flex justify-content-between">
            <span>Hosting & Lizenzen pro Monat</span>
            <strong>
              CHF {this.props.costPerMonth}
              {this.props.costPerMonth % 1 === 0 ? ".-" : ""}
            </strong>
          </li>
        </ul>
        {this.props.promoCode.state === "inactive" ? (
          <form onSubmit={this.handleSubmit}>
            <div className="input-group">
              <input
                type="text"
                value={this.state.promoCode}
                onChange={this.handleChange}
                name="promoCode"
                className="form-control"
                placeholder="Gutscheincode"
              ></input>
              <button className="btn btn-secondary">Einlösen</button>
            </div>
          </form>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default Entries;
